import React, { useState } from 'react';
import logo from '../logo.png';

const Nav = () => {
  const [opened, setOpened] = useState(true);

  return (
    <header className='w-100'>
      <div className='container'>
        <header className='flex jcsb aic h-10 py-5'>
          <h1 id='logo'>
            <div className='s-10'></div>
            <a href='/' aria-label='Logotype'>
              <img
                src={logo}
                width={200}
                height={64}
                alt='template'
                style={{ transform: 'translateY(-5px)' }}
              />
            </a>
          </h1>
          <div
            className={opened ? 'burger' : 'burger open'}
            onClick={() => setOpened(!opened)}
          >
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a
              href='/'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Головна
            </a>
            <a
              href='/about'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Про нас
            </a>
            <a
              href='/faq'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              F.A.Q
            </a>
          </div>
        </header>
      </div>
    </header>
  );
};

export { Nav };
