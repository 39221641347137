import React from 'react';

import pic from '../../assets/intro-pic.jpg';

const Intro = () => {
  return (
    <section id='top'>
      <div className='container'>
        <div className='s-80'></div>
        <img
          src={pic}
          alt='feature'
          width={640}
          height={240}
          className='obfc'
        />
        <div className='s-40'></div>
        <p className='title c-theme'>
          Реалізуйте свій потенціал, працюючи над проектами, що мають суспільне
          значення!
        </p>
        <div className='s-20'></div>
        <p className='title-sub'>
          Отримайте цінний досвід та розвиньте свої навички разом з нами,
          працюючи над важливими проектами, які спрямовані на покращення життя
          громади.
        </p>
        <p>
          Ми пропонуємо унікальну платформу для тих, хто хоче реалізувати свої
          знання та навички в реальних проектах з соціальним значенням. Наша
          ціль — створити середовище, де кожен зможе внести свій вклад у важливі
          проекти та отримати необхідний досвід для подальшого професійного
          зростання.
        </p>
        <div className='s-10'></div>
        <a
          href='https://forms.gle/4nA3v4tKXY5Jgns56'
          target='blank'
          className='button'
        >
          Приєднатися
        </a>
        <div className='s-40'></div>
      </div>
    </section>
  );
};

export { Intro };
