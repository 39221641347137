import React from 'react';
import { Nav } from '../components/Nav';
import pic from '../assets/intro-pic.jpg';

function FAQ() {
  return (
    <>
      <Nav />
      <section id='top'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-80'></div>
              <img
                src={pic}
                alt='feature'
                width={640}
                height={240}
                className='obfc'
              />
              <div className='s-60'></div>
              <h2 className='title c-theme tc'>Часті Запитання (FAQ)</h2>
              <div className='s-60'></div>
              <ol>
                <li>
                  <h4 className='title-sub mb-4'>Що таке ГO «ЕлектронНік»?</h4>
                  <p>
                    ГO «ЕлектронНік» - це громадська організація, яка допомагає
                    молодим IT-спеціалістам набути практичного досвіду через
                    участь у реальних соціально значущих проектах. Наша мета -
                    забезпечити учасникам необхідний досвід, щоб вони могли
                    знайти свою першу роботу в галузі.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Де розташована ваша організація?
                  </h4>
                  <p>
                    Наша організація знаходиться за адресою: Україна, Одеська
                    обл., місто Одеса, вул.Старопортофранківська, будинок 107,
                    квартира 21.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Як я можу з вами зв'язатися?
                  </h4>
                  <p>
                    Ви можете зв'язатися з нами через контактну форму на нашому
                    сайті.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Як я можу стати учасником вашої організації?
                  </h4>
                  <p>
                    Щоб стати учасником, вам потрібно заповнити форму на нашому
                    сайті, після чого ми зареєструємо вас в нашому Slack.
                    Важливо мати базові навички в IT та бажання набути
                    практичного досвіду.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Чи потрібно оплачувати участь у вашій організації?
                  </h4>
                  <p>Ні, участь у нашій організації абсолютно безкоштовна.</p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Як ви обираєте проекти для учасників?
                  </h4>
                  <p>
                    Ми співпрацюємо з різними громадськими організаціями, яким
                    потрібна IT-підтримка, і обираємо проекти, які мають
                    соціальну значущість.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Який термін участі в проектах?
                  </h4>
                  <p>
                    Термін участі в проектах варіюється від 6 до 18 місяців.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Який досвід я отримаю під час участі в проектах?
                  </h4>
                  <p>
                    Під час участі в проектах ви отримаєте практичний досвід,
                    який допоможе вам знайти роботу в IT-галузі після завершення
                    18-місячного терміну.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Як я можу отримати підтримку або консультації?
                  </h4>
                  <p>
                    Ви можете отримати підтримку та консультації в нашому Slack
                    каналі.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Як ви вимірюєте успіх проектів та учасників?
                  </h4>
                  <p>
                    Основним показником успіху є кількість учасників, яким ми
                    допомогли знайти роботу.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Чи можу я стати ментором у вашій організації?
                  </h4>
                  <p>
                    Так, ми завжди раді вітати досвідчених професіоналів як
                    менторів.
                  </p>
                </li>
                <li>
                  <h4 className='title-sub mb-4'>
                    Як я можу підтримати вашу організацію?
                  </h4>
                  <p>
                    Ви можете розповісти про нас своїм знайомим та колегам, а
                    також в соціальних мережах, щоб привернути увагу до нашої
                    діяльності та залучити більше учасників та партнерів.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
      </section>
    </>
  );
}

export { FAQ };
